<template>

    <div class="wrapper">
        <div class="animated fadeIn">
            <!-- 판매관리 --------------------------------------------------------------------------------------------->
            <BCard>
                <BCard header-tag="header" footer-tag="footer" class="mb-1" >

                    <div slot="header">
                        <BIconGiftFill/> <strong> 결제내역 </strong>
                        <div class="card-header-actions">
                            <small class="text-muted">주문 결제 조회 및 구매 취소</small>
                        </div>
                    </div>

                    <BRow class="mb-2">

                        <BCol sm="7">
                            <BInputGroup size="sm">
                                <BInputGroupPrepend>
                                    <BFormSelect size="sm"
                                                 v-model="dateGbn"
                                                 :options="dateGbnOpt"
                                                 @input="setdateGbn"/>
                                </BInputGroupPrepend>
                                <BInputGroupAppend>
                                    <BFormInput v-model="fromDate"
                                                type="date"
                                                size = "sm"
                                                @input="setPayDate"/>
                                </BInputGroupAppend>

                                <BInputGroupAppend>
                                    <BFormInput v-model="toDate"
                                                type="date"
                                                size="sm"
                                                @input="setPayDate"/>
                                </BInputGroupAppend>
                                <BInputGroupAppend>
                                    <BFormSelect size="sm" class="ml-3"
                                                 v-model="searchField"
                                                 :options="searchOpts"/>
                                </BInputGroupAppend>
                                <BInputGroupAppend>
                                    <BFormInput size="sm"
                                                v-model="searchWord"
                                                type="text"
                                                @keyup.enter="getPaymentList"/>
                                </BInputGroupAppend>
                            </BInputGroup>

                        </BCol>
                        <BCol sm="2">
                            <BButtonGroup>
                                <BButton size="sm" variant="primary" @click="getPaymentList"><BIconArrowRepeat/> Refresh</BButton>
                                <BButton size="sm" variant="info" @click="exportExcel" class="ml-1"><BIconFileSpreadsheetFill/> 다운로드</BButton>
                            </BButtonGroup>
                        </BCol>

                    </BRow>

                    <BRow>
                        <BCol>
                            <vue-excel-editor
                                    v-model="rows"
                                    ref="paymentGrid"
                                    width="100%"
                                    class="mb-1"
                                    :page="numOfRecord"
                                    :readonly-style="{backgroundColor:'#EFE'}"
                                    @select="selectPayment"
                                    :localized-label="$localizedLabel"
                                    filter-row>
                                <vue-excel-column field="trs_no" label="결제#" type="number" width="60px" key-field readonly/>
                                <vue-excel-column field="Moid" label="주문#" type="number" width="60px"/>
                                <vue-excel-column field="reg_dt" label="결제일시" type="datetime" width="140px"/>
                                <vue-excel-column field="PayMethod" label="지불수단" type="map" width="100px" :options="payMethods"/>
                                <vue-excel-column field="MID" label="상점아이디"  width="90px"/>
                                <vue-excel-column field="TID" label="거래번호"  width="120px"/>
                                <vue-excel-column field="Amt" label="거래금액"  type="number" width="90px"/>
                                <vue-excel-column field="BuyerName" label="구매자"  width="90px"/>
                                <vue-excel-column field="comp_name" label="회사명"  width="100px"/>
                                <vue-excel-column field="GoodsName" label="상품명"  width="100px"/>
                                <vue-excel-column field="AuthDate" label="승인일자"  type="string" width="90px"/>
                                <vue-excel-column field="AuthCode" label="승인번호"  width="80px"/>
                                <vue-excel-column field="ResultCode" label="결과코드"  width="80px"/>
                                <vue-excel-column field="ResultMsg" label="결과메시지"  width="120px"/>
                                <vue-excel-column field="cncl_yn" label="취소YN" type="checkYN" width="60px"/>
                                <vue-excel-column field="cncl_dt" label="취소일시" type="datetime" width="110px"/>
                                <vue-excel-column field="ErrorCode" label="에러코드"  width="80px"/>
<!--                                <vue-excel-column field="ErrorMsg" label="에러메시지"  width="120px"/>-->
                                <vue-excel-column field="fn_name" label="카드사"  width="90px"/>
                                <vue-excel-column field="AcquCardCode" label="매입사"  type="map" :options="acquCodes" width="80px"/>
                                <vue-excel-column field="CardNum" label="카드번호"  width="130px"/>
                                <vue-excel-column field="CardQuota" label="할부"  width="80px"/>
                                <vue-excel-column field="reg_dt" label="등록일" type="date" width="95px" readonly/>
                            </vue-excel-editor>
                        </BCol>
                    </BRow>

                    <BRow>
                        <BCol>
                            <BButtonGroup>
                                <BButton variant="primary" class="ml-1" @click="sendDocument">
                                    <BIconJournalMedical/> 인증서 전송
                                </BButton>
                                <BButton variant="danger" class="ml-1" @click="cancelPayment">
                                    <BIconCartXFill/> 결제 취소 및 주문 취소
                                </BButton>
                            </BButtonGroup>
                        </BCol>

                        <BCol>
                            <BProgress height="2rem" :value="progCount" :max="totalCount" variant="warning" show-value/>
                        </BCol>
                    </BRow>



                </BCard>


            </BCard>

        </div>
    </div>
</template>



<script>
    //-------------------------------------------------------------------------------------------------
    import {alertSync, alertConfirm, apiCall, cloneVar, checkBisNo, toastSync, alertWarn} from '../../../common/utils';
    import qs from 'querystring';
    import moment from "moment";

    import {
        PayTypeOpts, 
        TaxStatOpts, 
        PayStatOpts, 
        SendStatOpts, 
        PayMethods, 
        AcquCodes, 
        BankCodes,
    } from '../../../common/salesType';

    /*
    let _order = {
        ord_no       : null,
        prd_code     : null,
        unit_price   : null,
        qty          : null,
        warranty_mon : null,
        price        : null,
        pay_amt      : null,
        pay_type     : null,
        user_seq     : null,
        ord_dt       : null,
        email        : null,
        name         : null,
        phone_no     : null,
        comp_no      : null,
        comp_name    : null,
        pr_name      : null,
        biz_kind     : null,
        address      : null,
        zip_code     : null,
        appr_yn      : null,
        appr_dt      : null,
        send_stat    : null,
        send_dt      : null,
        pay_stat     : null,
        pay_dt       : null,
        cncl_yn      : null,
        cncl_dt      : null,
        cncl_desc    : null,
        description  : null,
        reg_dt       : null,
        upd_dt       : null,
        reg_id       : null,
        upd_id       : null,
    };
*/
    //----------------------------------------------------------------------------------------------------
    export default {
        name: 'PaymentInfo',
        data () {
            return {
                prdCodeOpts: {},
                selectedPrd: null,
                prdOpts: [],
                prdInfo: {},
                searchOpts: [
                    {value:'name',       text:'이름/회사명'},
                    {value:'email',      text:'전자우편'},
                    {value:'phone_no',   text:'전화번호'},
                    {value:'prd_name',   text:'제품명'},
                    {value:'cu_id',      text:'아이디'},
                    {value:'prd_code',   text:'제품코드'}
                ],
                dateGbn: "pay",
                dateGbnOpt: [
                    {value:'pay', text:'결제일'}
                    ,{value:'ord', text:'주문일'}
                    ,{value:'cncl', text:'취소일'}
                    ,{value:'reg', text:'등록일'}
                ],
                payTypeOpts: PayTypeOpts,
                taxStatOpts: TaxStatOpts,
                payStatOpts: PayStatOpts,
                sendStatOpts: SendStatOpts,
                payMethods: PayMethods,
                acquCodes: AcquCodes,
                bankCodes: BankCodes,
                statusOpt: {'00':'활성', '10':'준비', '90': '추출', '99':'만료'},
                logTypeOpts: {'I':'접속', 'O':'종료','S':'설치'},
                progCount: 0,
                totalCount: 0,

                rows: [],
                claimLogs: [],
                selectedIdx: null,
                selectedRow: null,
                fromDate: moment().format("YYYY-MM-01"),
                toDate: moment(this.fromDate).endOf("month").format("YYYY-MM-DD"),

                // fromDate: moment().subtract( 7,"days").format('YYYY-MM-DD'),
                // toDate: moment().format('YYYY-MM-DD'),
                /////////////////////////////////////////////////////
                searchWord : null,
                searchField: 'name',
                paramMap: {},

                numOfRecord: 20,

            }

        },

        async created(){
            try{
                this.paramMap['dateGbn'] = this.dateGbn;
                this.paramMap['toDate'] = this.toDate;
                this.paramMap['fromDate'] = this.fromDate;
                await this.getPrdCode();
                await this.getPaymentList();
            }catch(err){
                console.log(err);
            }
        },
        computed: {

        },

        mounted() {
            console.log("mounted---------------------ProductToken");
            // below is not work!
        },

        methods: {

            setPayDate(){
                this.toDate =  moment(this.fromDate).endOf("month").format("YYYY-MM-DD");
                this.paramMap['dateGbn'] = this.dateGbn;
                this.paramMap['toDate'] = this.toDate;
                this.paramMap['fromDate'] = this.fromDate;
                this.getPaymentList();
            },

            async getPrdCode(){
                console.log( "getPrdCode ---- 1" );
                this.prdCodeOpts = {};
                this.prdOpts = [];
                this.prdOpts.push({value:null, text:'주문상품 선택'});
                try{
                    const r = await apiCall('get', `/admin/api/product/info`);

                    r.result.map(i=>{
                        this.prdCodeOpts[i.prd_code] = i.disp_name;
                        this.prdInfo[i.prd_code] = i;
                        this.prdOpts.push({value:i, text: i.prd_seq +' | '+i.disp_name, disabled:(i.use_yn!=="Y")});
                    });
                    // console.log( "prdCodeOpts ----> ", this.prdCodeOpts );
                    // console.log( "prdOpts ----> ", this.prdOpts );
                    // console.log( "prdInfo ----> ", this.prdInfo );

                }catch(err){}
            },

            async getPaymentList(){
                this.$refs['paymentGrid'].clearFilter();
                let r = null;
                let qry = '';
                if( this.searchWord !== null) this.paramMap[this.searchField] = this.searchWord.toUpperCase();
                qry = qs.stringify( this.paramMap );
                console.log( "query-string ---------- ", qry );
                try{
                    this.rows = [];
                    r = await apiCall('get', `/admin/api/payment?${qry}`);
                    console.log( "getPaymentList ----------> ", r.result);
                    if(r.result) this.rows = r.result;

                }catch(err){
                    console.log( err );
                }

            },
            setdateGbn(){
                this.paramMap['dateGbn'] = this.dateGbn;
                this.getPaymentList();
            },

            exportExcel(){
                const format = 'xlsx';
                const exportSelectedOnly = true;
                const filename = 'payment-list-'+moment().format('YYYY-MM-DD');
                this.$refs["paymentGrid"].exportTable(format, exportSelectedOnly, filename);
            },



            async selectPayment(idx, evt){
                if(!evt) return;
                // console.log( "selectProductToken --------> ", evt );
                //console.log( "selectPayment --------> ", this.$refs['paymentGrid'].getSelectedRecords() );
            },


            /**
             *  인증서 전송 ***
             * */
            async sendDocument(){
                let params = this.$refs['paymentGrid'].getSelectedRecords();
                this.progCount = 0;
                this.totalCount = params.length;
                if( !params.length ){
                    await alertWarn( this.$bvModal, "선택한 레코드가 없습니다.", "레코드를 선택 하세요");
                    return;
                }
                if( !(await alertConfirm(this.$bvModal, `${params.length} 개의 주문건에 대한 인증서를 전송합니다. 진행하시겠습니까?`, '인증서 전송')) ){
                    return;
                }

                let failCnt = 0;
                for( let order of params){
                    console.log( "Send Cert Document order --------->", order );
                    if( order.appr_yn==='Y'){
                        order.prd_name = this.prdInfo[order.prd_code].prd_name; // prd name 세팅
                        const r = await apiCall('POST', `/admin/api/sales/send-cert`, order );
                        console.log("sendDocument----result--->", r);
                        if( r.result.affectedRows ){
                            this.progCount++;
                            order.send_yn = 'Y';
                            order.send_stat = '00';
                            order.send_dt = moment().format("YYYY-MM-DD HH:mm:ss");
                        }else{
                            console.log( "====> 전송실패: ", r );
                            order.send_stat = '90';
                            failCnt++;
                        }
                    }else{
                        failCnt++;
                        console.log( "====> 미승인 주문: ", order );
                    }
                }
                await alertSync(this.$bvModal, {text:`전송: ${this.progCount}건, 실패: ${failCnt}`});
            },




            validateCell(val, oldVal, rec, field){
                console.log("----------------validateCell-------------------");
                console.log( "validateCell---value ---> ", val );
                console.log( "validateCell---oldVal ---> ", oldVal );
                console.log( "validateCell---rec ---> ", rec );
                console.log( "validateCell---field ---> ", field );
                // console.log( "validateCell---",this.$refs['paymentGrid'] );
                // console.log( "validateCell---",this.$refs['paymentGrid'].selectRecord(0) );
            },
            isSuccessResult(code) {
                if( code==='3001' || code==='4000' || code==='A000' || code==='2001' || code==='2211' || code==='KP00' ||  code==='NP00' )
                    return true;
                else
                    return false;
            },
            async cancelPayment(recs){
                let r = null, msg = null, notice = null;

                try{
                    console.log( recs );
                    let params = this.$refs['paymentGrid'].getSelectedRecords();
                    this.progCount = 0;
                    this.totalCount = params.length;
                    console.log( "cancelPayment ----> ", params );
                    console.log( "cancelPayment length ----> ", params.length );
                    if( !params.length ) {
                        await alertSync(this.$bvModal, {text:'결제항목을 선택하세요'});
                        return;
                    }

                    const confirmMsg = `${params.length} 건의 결제를 취소 합니다. 취소가 완료되면 발급된 제품키도 삭제됩니다. 진행 하시겠습니까?`;

                    if( !(await alertConfirm(this.$bvModal, confirmMsg, '주문취소')) ){
                        return;
                    }

                    // this.$refs['paymentGrid'].deleteSelectedRecords();
                    let failCnt = 0;
                    for( let pay of params){
                        console.log( "cancelPayment for --------->", pay );
                        if( this.isSuccessResult( pay.ResultCode ) && pay.cncl_yn!=='Y' ){
                            // const tIndex = this.$refs['paymentGrid'].rowIndex[pay.$id];
                            const r = await apiCall('POST', '/admin/api/payment/cancel', pay );
                            console.log( r );
                            if( r.result && r.result.affectedRows!==0 ){
                                this.progCount++;
                                pay.cncl_yn = 'Y';
                                pay.cncl_dt = moment().format('YYYY-MM-DD HH:mm:ss');
                            }else{
                                failCnt++;
                                await alertSync(this.$bvModal, {text:`주문번호 ${pay.ord_no}, 취소실패: ${r.message} `});
                            }
                        }else{
                            failCnt++;
                            console.log( "====> 실패한 결제 데이터 또는 기취소 --- skip : ", pay );
                        }
                    }

                    await alertSync(this.$bvModal, {text:`결제 취소: ${this.progCount} 건, 취소 실패: ${failCnt}`});
                    this.$refs['paymentGrid'].clearAllSelected();
                    await this.getPaymentList();

                }catch(err){

                    console.log( err );
                }
            },

        }
    }
</script>
